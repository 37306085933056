<template>
  <div class="Registration_root">
    <v-form ref="form">
      <TextFieldInput
        :value="form.email"
        placeholder="random@gmail.com"
        name="email"
        :rules="[...inputRules.required, ...inputRules.email]"
        label="email address"
        hasShadow
        @input="handleInput(arguments[0], 'email')"
        removeSpacesHandler
      />
      <TextFieldInput
        :value="form.password"
        placeholder="************"
        type="password"
        name="password"
        label="password"
        hasShadow
        :rules="[...inputRules.required, ...inputRules.password]"
        :passwordInput="true"
        @input="handleInput(arguments[0], 'password')"
        removeSpacesHandler
      />
      <TextFieldInput
        v-model="confirmPassword"
        placeholder="************"
        type="password"
        name="password"
        label="confirm password"
        hasShadow
        :rules="[...inputRules.required, ...passwordConfirmationRule]"
        :passwordInput="true"
        removeSpacesHandler
      />
      <div class="Registration_links">
        <Checkbox :value="acceptRules" @input="handleAcceptRules">
          <div class="Registration_checkbox-label" slot="label">
            I accept the terms of <router-link tag="a" to="/docs/UserAgreement.pdf" target="_blank">user agreement</router-link>
          </div>
        </Checkbox>
      </div>
      <Button fullWidth brightBtn size="large" text="Create Account" @click="handleRegister" />
      <div class="Registration_link">
        <div>Already registered? <router-link :to="{ name: 'login' }">Log In</router-link></div>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import inputRules from '@/utils/inputRules';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import Checkbox from '@/components/_common/FormElements/Checkbox/Checkbox';
import Button from '@/components/_common/FormElements/Button/Button';
import { showAppNotification } from '@/utils/appNotification';
import { validateForm } from '@/mixins/validateForm';

export default {
  components: {
    TextFieldInput,
    Checkbox,
    Button,
  },
  mixins: [validateForm],
  data: () => ({
    inputRules,
    confirmPassword: '',
    form: {
      email: '',
      password: '',
    },
    acceptRules: false,
  }),
  computed: {
    passwordConfirmationRule() {
      return () => this.confirmPassword == this.form.password || 'Passwords do not match';
    },
  },
  methods: {
    ...mapActions('user', ['apiSignupAction', 'apiSigninAction']),
    handleInput(value, field) {
      this.form[field] = value;
    },
    handleAcceptRules(value) {
      this.acceptRules = value;
    },
    handleRegister() {
      this.validateForm().then(() => {
        if (!this.acceptRules) {
          showAppNotification('error', 'Please, accept terms of user agreement!');
        } else {
          this.apiSignupAction(this.form).then(() => this.$router.push({ name: 'registration.email.verification' }));
        }
      });
    },
  },
};
</script>

<style lang="scss">
.Registration_root {
  max-width: 400px;
  margin: 0 auto;

  input {
    font-size: 16px;
  }
}
.Registration_links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  margin: 0 0 25px;
  div {
    flex: 0 1 auto;
    a {
      color: #2af3f3;
      text-decoration: none;
    }
  }
}

.Registration_link {
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  margin: 15px 0;
  font-weight: 500;
  a {
    color: #2af3f3;
    text-decoration: none;
  }
}

.Registration_links {
  .Registration_checkbox-label {
    font-size: 12px;
    line-height: 22px;
    color: #ffffff;
    margin-left: 5px;
  }

  .v-input.v-input--selection-controls.v-input--checkbox {
    margin: 0;
    .v-input__control {
      .v-input__slot {
        .v-input--selection-controls__input {
          i {
            color: rgba(#fff, 0.04) !important;
          }
        }
      }
    }
  }
}
</style>
